<template>
  <div id="preload" class="preload-component">
    <div id="loading">
      Loading...
      {{
        Math.round(
          ((percentCompletedImages + percentCompletedAudio) / 2) * 100
        )
      }}%
    </div>
  </div>
</template>

<script>
import { Howl } from "howler";
import _ from "underscore";
import { FocusManager } from "../focusManager";

export default {
  name: "Preload",
  data() {
    return {
      audioNames: [],
      imageLength: 0,
      percentCompletedAudio: 0,
      percentCompletedImages: 0,
    };
  },
  mounted: function () {
    this.$store.commit("initGame");
    this.$store.state.focusManager = new FocusManager();
    for (let row of this.$store.state.round4) {
      _.has(row, "imageAlt") ? this.imageLength++ : "";
    }
    const rounds = [
      this.$store.state.round1,
      this.$store.state.round2,
      this.$store.state.round3,
      this.$store.state.round4,
    ];
    for (const [index, round] of rounds.entries()) {
      for (let row of round) {
        let fileName = _.get(row, "id");
        if ((fileName, fileName.indexOf("-") === 1)) {
          this.audioNames.push(fileName);
        }
        if (index === 3) {
          let feedbackFileName = _.get(row, "audio");
          if ((fileName, fileName.indexOf("-") === 1)) {
            feedbackFileName ? this.audioNames.push(feedbackFileName) : "";
          }
        }
        if (fileName === "title") {
          let titleFileName = _.get(row, "audio");
          this.audioNames.push(titleFileName);
        }
      }
    }
    // add misc vo files
    this.audioNames.push("should-you-post");
    this.audioNames.push("basketball-award");
    this.audioNames.push("baseball-award");
    this.audioNames.push("football-award");
    this.audioNames.push("soccer-award");
    this.audioNames.push("cyberbullying-tips");
    this.audioNames.push("win-game");

    this.preload();
  },
  methods: {
    async preload() {
      await this.preloadImages();
      await this.preloadAudio();
      if (this.$store.state.devMode) {
        this.$store.commit("navigate", { route: "Dialogue" });
      } else {
        this.$store.commit("navigate", {
          route: "Title",
          data: { title: "Boston vs. Bullies Academy for Internet Champions" },
        });
      }
    },
    async preloadImages() {
      let loaded = 0;
      return new Promise((resolve) => {
        for (let row of Object.values(this.$store.state.round4)) {
          if (String(row.image).includes(".jpg")) {
            this.preloadImage(row.image).then(() => {
              loaded++;
              this.percentCompletedImages = loaded / this.imageLength;

              if (this.percentCompletedImages >= 1) resolve();
            });
          }
        }
      });
    },
    async preloadImage(src) {
      return new Promise(
        (resolve, reject) => {
          let img = new Image();
          img.onload = () => {
            resolve();
          };
          img.onerror = () => {
            reject();
          };
          img.src = require("../assets/round4/" + src);
        },
        () => {
          this.preloadImage(src);
        }
      );
    },
    async preloadAudio() {
      let loaded = 0;

      return new Promise((resolve) => {
        this.$store.state.backgroundMusic = new Howl({
          src: require("../assets/music.mp3"),
          volume: 0.07,
          loop: true,
        });
        if (this.audioNames.length === 0) {
          this.percentCompletedAudio = 1;
          resolve();
        } else {
          for (let sound of this.audioNames) {
            var name = sound + ".mp3";

            this.$store.state.loadedSounds[sound] = new Howl({
              src: require("../assets/vo/" + name),
              onload: () => {
                loaded++;
                this.percentCompletedAudio = loaded / this.audioNames.length;
                if (this.percentCompletedAudio >= 1) resolve();
              },
            });
          }
        }
      });
    },
  },
};
</script>
